"use client";
import { useState, FC, useEffect } from "react";
import { signInWithGoogle, signInWithEmailAndPassword } from "../actions";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import Link from "next/link";
import { useSessionContext } from "@/context/useSessionContext";
import { useRouter } from "next/navigation";

const Login: FC = () => {
  const [loadingGoogleSignIn, setLoadingGoogleSignIn] = useState(false);
  const [loadingPasswordSignIn, setLoadingPasswordSignIn] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const router = useRouter();
  const searchParams = new URLSearchParams(
    typeof window !== "undefined" ? window.location.search : ""
  );
  const { session, setSession } = useSessionContext();

  useEffect(() => {
    if (session !== null) {
      router.push("/");
    }
  }, [router, session]);

  const handleEmailPasswordSignIn = async () => {
    setLoadingPasswordSignIn(true);
    const { error, data } = await signInWithEmailAndPassword(email, password);
    setLoadingPasswordSignIn(false);
    if (error) {
      alert(
        "Invalid email or password. Please check if you've signed up with Google previously."
      );
      return;
    }
    setSession(data.session);
  };

  return (
    <div className="absolute flex justify-center items-center bg-[url('/background-mobile.png')] sm:bg-[url('/background.png')] w-screen h-screen bg-cover top-0 left-0 z-50">
      <div className="flex flex-col items-center text-primary-10 max-w-sm mx-4 w-full rounded-md border border-neutral-3 shadow-lg">
        <div className="flex flex-col items-center gap-4 px-4 sm:px-8 py-10 w-full rounded-t-md bg-white">
          <div className="text-xl font-semibold">Welcome back</div>
          <Button
            className="w-full h-10"
            loading={loadingGoogleSignIn}
            size="lg"
            variant="white"
            loaderColor="text-primary-10"
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="20"
                height="20"
                viewBox="0 0 48 48"
              >
                <path
                  fill="#FFC107"
                  d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"
                ></path>
                <path
                  fill="#FF3D00"
                  d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"
                ></path>
                <path
                  fill="#4CAF50"
                  d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"
                ></path>
                <path
                  fill="#1976D2"
                  d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"
                ></path>
              </svg>
            }
            onClick={() => {
              setLoadingGoogleSignIn(true);
              signInWithGoogle();
            }}
          >
            Sign in with Google
          </Button>
          <div className="text-center flex w-full items-center gap-1">
            <div className="w-full bg-primary-10/30 h-[1px]" />
            <div className="font-semibold text-sm text-primary-10/30">OR</div>
            <div className="w-full bg-primary-10/30 h-[1px]" />
          </div>
          <div className="flex flex-col w-full gap-3">
            <Input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full"
            />
            <Input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full"
            />
            <Button
              className="w-full h-10"
              loading={loadingPasswordSignIn}
              size="lg"
              variant="default"
              onClick={handleEmailPasswordSignIn}
            >
              Sign in
            </Button>
          </div>
        </div>
        <div className="text-xs text-center py-2 text-primary-10 bg-slate-100 w-full rounded-b-md">
          <Link href={`/register?${searchParams.toString()}`}>
            <span className="font-medium">Don&apos;t have an account? </span>
            <span className="underline font-bold">Register</span>{" "}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Login;
